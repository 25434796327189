import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['enabledCheckbox', 'cadenceSelect']

  connect() {
    this.boundToggleCadenceSelect()
  }

  enabledCheckboxTargetConnected(element) {
    element.addEventListener('change', this.boundToggleCadenceSelect)
  }

  enabledCheckboxTargetDisconnected(element) {
    element.removeEventListener('change', this.boundToggleCadenceSelect)
  }

  toggleCadenceSelect(_event) {
    const savingsReportEnabled = this.enabledCheckboxTarget.checked

    if (!!savingsReportEnabled) {
      this.cadenceSelectTarget.removeAttribute('disabled')
      this.cadenceSelectTarget.classList.toggle('d-none', false)
    } else {
      this.cadenceSelectTarget.setAttribute('disabled', 'disabled')
      this.cadenceSelectTarget.classList.toggle('d-none', true)
    }
  }

  get boundToggleCadenceSelect() {
    return this.toggleCadenceSelect.bind(this)
  }
}
