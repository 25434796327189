import { Controller } from '@hotwired/stimulus'
import { validateDocumentContentType, buildDocumentEl } from '@utils/documents'

export default class extends Controller {
  static targets = [
    'userIdSelect', 'scientistInput', 'documentsInput',
    'documentsList', 'hlEquivalenceIndicator'
  ]

  userIdSelectTargetConnected(element) {
    $(element).selectize({
      valueField: 'id',
      labelField: 'name',
      searchField: 'name',
      create: false,
      maxItems: 1,
      onChange: value => {
        if (!!value) {
          this.userIdSelectTarget.value = value
          this.scientistInputTarget.value =
            this.userIdSelectTarget.closest('.form-group')?.querySelector(`div.item[data-value="${value}"]`)?.innerText
        } else {
          this.userIdSelectTarget.value = ''
          this.scientistInputTarget.value = ''
        }
      }
    })

    if (element.disabled) {
      const formGroupEl = element.closest('.form-group')
      formGroupEl?.querySelector('.selectize-control')?.setAttribute('disabled', 'disabled')
      formGroupEl?.querySelector('.selectize-input input')?.setAttribute('disabled', 'disabled')
    }
  }

  onDocumentSelect(event) {
    Array.from(event.target.files).forEach(file => {
      const invalidContentTypeCallback = () => this.documentsInputTarget.value = ''

      if (validateDocumentContentType(file, invalidContentTypeCallback)) {
        const [documentId, documentEl] = buildDocumentEl({
          name: file.name,
          previewUrl: window.URL.createObjectURL(file),
          deleteAction: 'click->request-item--edit-modal#removeDocument'
        })

        this.documentsListTarget.insertAdjacentHTML('beforeend', documentEl)
        file.id = documentId
      }
    })
  }

  removeDocument(event) {
    const container = event.target.closest('.document-container')
    const fileBuffer = new DataTransfer()

    Array.from(this.documentsInputTarget.files).forEach(file => {
      if (file.id.toString() !== container.dataset.id?.toString())
        fileBuffer.items.add(file)
    })

    this.documentsInputTarget.files = fileBuffer.files
    container.remove()
  }

  hlEquivalenceIndicatorTargetConnected(element) {
    const tooltipEl = $(element)
    tooltipEl?.tooltip({ trigger: 'hover' })
    tooltipEl?.click(function () { tooltipEl.tooltip('hide') })
  }
}
