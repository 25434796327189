import '@hotwired/turbo-rails'
import '~/controllers/index.js' // Stimulus JS
import { gtag } from "@utils/url_helpers";

document.addEventListener("turbo:load", (event) => {
    let tagEl = document.querySelector("meta[name='ua-tag']")

    if (tagEl) {
        let googleId = tagEl.content
        let debugEl = document.querySelector("meta[name='ua-debug']")
        let typeEl = document.querySelector("meta[name='ua-type']")

        gtag('js', new Date());
        gtag('config', googleId, { 'debug_mode': !!debugEl });

        if (typeEl) {
            let uType = typeEl.content

            gtag('set', 'user_properties', { type: uType })
        }
    }
})
