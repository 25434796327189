import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['refreshEtaModal']

  refreshEtaModalTargetConnected(element) {
    const frame = document.getElementById('eta_modal')
    const frameSrc = element.dataset.frameSrc

    if (!frame || !frameSrc) return

    frame.src = frameSrc
    frame.reload()
  }
}
