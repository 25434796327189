import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['documentKindSelect']

  documentKindSelectTargetConnected(element) {
    // To trigger orderForm validation check + submit button control
    element.dispatchEvent(new Event('blur', { bubbles: true }))
  }

  deleteOrderDocument(event) {
    event.preventDefault()
    this.element.remove()
  }
}
