import { Controller } from '@hotwired/stimulus'
import { capitalize } from 'lodash'
import { sumReducer } from '@utils/calculations'
import { get } from '@rails/request.js'
import { objectToQueryString } from '@utils/url_helpers'

export default class extends Controller {
  static values = {
    isExpanded: { type: Boolean, default: true },
    shipmentIndex: { type: Number, default: -1 },
    labPublicUid: String,
    shipmentId: String,
    shipmentStatuses: Array,
    shipmentStatusIcons: {
      type: Object,
      default: {
        open: 'far fa-circle',
        requested: 'far fa-clock',
        backordered: 'fas fa-exclamation-triangle',
        closed: 'fas fa-check',
        canceled: 'fas fa-times',
        partial: 'fas fa-adjust',
        urgent: 'fas fa-exclamation-circle'
      }
    },
  }

  static targets = [
    'headerCaretIcon', 'headerSummary', 'headerEtaDate', 'shipmentForm', 'shipmentStatusSelect', 'lineItems', 'lineItem',
    'removeShipmentEl', 'removeShipmentBtn', 'subtotalCostInput', 'totalCostInput', 'shipmentNotes'
  ]

  toggleExpanded(event) {
    event.preventDefault()
    event.stopPropagation()
    this.isExpandedValue = !this.isExpandedValue
  }

  isExpandedValueChanged() {
    // Update header caret icon
    this.headerCaretIconTarget.classList.toggle('fa-caret-down', !this.isExpandedValue)
    this.headerCaretIconTarget.classList.toggle('fa-caret-up', this.isExpandedValue)

    // Show/Hide header information
    this.headerSummaryTarget.classList.toggle('d-none', this.isExpandedValue)
    this.headerEtaDateTarget.classList.toggle('d-none', this.isExpandedValue)
    this.shipmentFormTarget.classList.toggle('d-none', !this.isExpandedValue)
    this.lineItemsTarget.classList.toggle('d-none', !this.isExpandedValue)
    this.removeShipmentElTarget.classList.toggle('d-none', !this.isExpandedValue)
    this.shipmentNotesTarget.classList.toggle('d-none', !this.isExpandedValue)
  }

  shipmentStatusSelectTargetConnected() {
    const buildStatusOption = (item, wrapper = false) => {
      const statusPill =
        `<span class="badge badge-pill badge-status badge-${item.value}">` +
          `<i class="${this.shipmentStatusIconsValue[item.value]} status-icon" />` +
          item.label +
        '</span>'

      if (!wrapper) return statusPill
      return `<div class="d-flex px-2 py-2">${statusPill}<div>`
    }

    $(this.shipmentStatusSelectTarget).selectize({
      maxItems: 1,
      valueField: 'value',
      labelField: 'label',
      options: this.shipmentStatusesValue.map(shipmentStatus => ({
        value: shipmentStatus, label: capitalize(shipmentStatus)
      })),
      render: {
        item: (item, _escape) => buildStatusOption(item),
        option: (item, _escape) => buildStatusOption(item, true)
      }
    })
  }

  shipmentIndexValueChanged(currentIndex, _oldIndex) {
    if (currentIndex === -1) return

    const shipmentIndexEl = this.element.querySelector('span.shipment-index')
    if (shipmentIndexEl) shipmentIndexEl.innerHTML = currentIndex + 1
  }

  lineItemTargetConnected(_element) {
    // TODO: change this to consider removed line items

    this.adjustLineItemIndexes()
    this.setLineItemsCount()
    this.toggleRemoveShipmentBtn()
    this.calculateTotals()
  }

  lineItemTargetDisconnected(_element) {
    this.adjustLineItemIndexes()
    this.setLineItemsCount()
    this.toggleRemoveShipmentBtn()
    this.calculateTotals()
  }

  adjustLineItemIndexes() {
    this.lineItemTargets.forEach((item, index) => {
      // Sets the lineItemIndexValue - which in turn triggers the lineItemIndexValueChanged callback in the LineItem controller
      item.dataset.lineItemLineItemIndexValue = index

      // Dispatch events in order to trigger form controller validations and enable/disable submit button
      item.querySelectorAll('input').forEach(inputItem => {
        inputItem.dispatchEvent(new Event('input', { bubbles: true }))
        inputItem.dispatchEvent(new Event('blur', { bubbles: true }))
      })
    })
  }

  setLineItemsCount() {
    const lineItemsCountEl = this.element.querySelector('span.line-items-count')

    if (lineItemsCountEl) {
      const lineItemsCount = this.lineItemTargets.length
      const countableLineItems = lineItemsCount === 1 ? 'Line Item' : 'Line Items'

      lineItemsCountEl.innerHTML = `${lineItemsCount} ${countableLineItems}`
    }
  }

  toggleRemoveShipmentBtn() {
    this.removeShipmentBtnTarget.disabled = this.lineItemTargets.length > 0
  }

  calculateTotals(_event) {
    const subtotalCost =
      this.lineItemTargets.map(item => Number(item.dataset.lineItemLineItemCostValue)).reduce(sumReducer, 0)

    // TODO: correctly calculate the totalCost (on UPDATE)
    const totalCost = subtotalCost

    this.subtotalCostInputTarget.value = subtotalCost
    this.totalCostInputTarget.value = totalCost
    this.element.querySelector('span.shipment-total-value').innerHTML = `$${(totalCost || 0).toFixed(2)}`

    this.dispatch('totalsUpdated', {})
  }

  deleteShipment(event) {
    event.preventDefault()
    this.element.remove()
  }

  async addNewLineItem(baseLineItem = null){
    const params = { shipment_id: this.shipmentIdValue }
    if (baseLineItem) params['line_item'] = baseLineItem

    const response =
      await get(`/labs/${this.labPublicUidValue}/shipment/shipment_line_item?${objectToQueryString(params)}`, {
        credentials: 'same-origin',
        responseKind: 'turbo-stream'
      })

    if (!response.ok) console.error('Failed to add new line item')
  }
}
