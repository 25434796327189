import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    document.getElementById('eta_modal')?.addEventListener('turbo:frame-load', event => {
      this.addNextCheckDateChangeListener(event.target)
      this.addSaveChangesClickListener()
      this.addScientistEmailChangeListener()
      this.addScientistEmailOverrideListener()
      this.initializeTooltips()
    })

    this.initializeTooltips()
  }

  disconnect () {
    document.getElementById('eta_modal').removeEventListener('turbo:frame-load')
  }

  addNextCheckDateChangeListener() {
    $("input[name='order[shipment][next_check_date]']").change(function (event) {
      $(this).next("input[name='order[shipment][next_check_date_updated]']").val(true)
    })
  }

  addSaveChangesClickListener(modal) {
    $('.btn-save-changes').click(function() {
      const validInputs = $(modal).find(':input').toArray().every(function(input) {
        return input.checkValidity()
      })

      if (validInputs) {
        $(this).hide()
        $('.btn-loading').show()
      }
    })
  }

  addScientistEmailChangeListener() {
    $('.scientist-email').change(function(event) {
      const email = event.target.value
      const shipmentId = $(this).data('shipmentId')
      const scientists = $(this).data('scientists')
      const originalMessage = $(this).data('originalMessage')
      const scientist = $.grep(scientists, function (scientist) { return scientist.email === email })[0]
      const message = $('#email-message-' + shipmentId)

      if (scientist) {
        message.val('Hi ' + scientist.first_name + ", \n" + originalMessage)
      } else {
        message.val("Hi, \n" + originalMessage)
      }
    })
  }

  addScientistEmailOverrideListener() {
    $('.scientist-email-override').on('input', function() {
      const originalMessage = $(this).data('originalMessage')
      const shipmentId = $(this).data('shipmentId')
      const message = $('#email-message-' + shipmentId)

      message.val("Hi, \n" + originalMessage)
    })
  }

  initializeTooltips() {
    const tooltipEl = $("[data-toggle='tooltip']")
    tooltipEl?.tooltip({ trigger: 'hover' })
    tooltipEl?.click(function () { tooltipEl.tooltip('hide') })
  }

  openModal(event) {
    const frame = document.getElementById('eta_modal')
    if (!frame) return

    frame.src = event.target.dataset.url
    frame.reload()
  }
}
