const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'xls', 'xlsx', 'csv', 'doc', 'docx']

export const validateDocumentContentType = (file, errorCallback = undefined) => {
  const matches = file.name.match(/\.([^\.]+)$/)
  const extension = matches ? matches[1] : null

  if (!extension || !allowedExtensions.includes(extension.toLowerCase())) {
    alert(`File extension not allowed. The allowed extensions are: ${allowedExtensions.join(', ')}`)
    errorCallback?.()

    return false
  }

  return true
}

export const generateDocumentId = () => new Date().getTime() + Math.ceil(Math.random() * 100)

/**
 * Build a new document element.
 * Returns (1) the data-id of the new element and (2) the element
 * */
export const buildDocumentEl =
  ({
     id = generateDocumentId(),
     previewUrl,
     name,
     deleteAction,
     deleteUrl = '{deleteUrl}'
  }) => ([
    id,
    `<div data-id="${id}" class="document-container mt-2">
      <a href="${previewUrl}" class="link document-preview mr-2" target="_blank" rel="noopener noreferrer">
        <div class="document-name">${name}</div>
      </a>
      <a data-action="${deleteAction}" data-delete-url="${deleteUrl}" class="link btn-delete-document">
        <i class="fas fa-times"></i>
      </a>
    </div>`
  ])
