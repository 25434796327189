import { Controller } from '@hotwired/stimulus'
import { validateDocumentContentType, buildDocumentEl } from '@utils/documents'

export default class extends Controller {
  static targets = ['documents', 'documentsInput', 'uploadedDocument', 'submitButton']
  static values = { csrfToken: String }

  connect () {
    if (this.uploadedDocumentTargets.length > 0) {
      this.uploadedDocumentTargets.forEach(document => {
        const [,documentEl] = buildDocumentEl({
          id: document.dataset.id,
          name: document.dataset.name,
          previewUrl: document.dataset.url,
          deleteAction: 'click->request-item--single-request-form#removeDocument',
          deleteUrl: document.dataset.deleteUrl
        })

        this.documentsTarget.insertAdjacentHTML('beforeend', documentEl)
      })
    }
  }

  onDocumentSelect(event) {
    Array.from(event.target.files).forEach(file => {
      const invalidContentTypeCallback = () => this.documentsInputTarget.value = ''

      if (validateDocumentContentType(file, invalidContentTypeCallback)) {
        const [documentId, documentEl] = buildDocumentEl({
          name: file.name,
          previewUrl: window.URL.createObjectURL(file),
          deleteAction: 'click->request-item--single-request-form#removeDocument'
        })

        this.documentsTarget.insertAdjacentHTML('beforeend', documentEl)
        file.id = documentId
      }
    })
  }

  removeDocument(event) {
    const container = event.target.closest('.document-container')
    const deleteUrl = event.currentTarget.dataset.deleteUrl
    const isPersisted = deleteUrl !== '{deleteUrl}'

    if (isPersisted) {
      fetch(deleteUrl, {
        method: 'DELETE',
        headers: { 'X-CSRF-Token': this.csrfTokenValue }
      }).then(() => {
        this.uploadedDocumentTargets.find(document => document.dataset.id === container.dataset.id).remove()
        container.remove()
      })
    } else {
      const files = this.documentsInputTarget.files
      const fileBuffer = new DataTransfer()

      Array.from(files).forEach(file => {
        if (file.id.toString() !== container.dataset.id?.toString()) {
          fileBuffer.items.add(file)
        }
      })

      this.documentsInputTarget.files = fileBuffer.files
      container.remove()
    }
  }

  get submitButton() {
    if (this.hasSubmitButtonTarget) return this.submitButtonTarget
    return this.element.querySelector('input[type="submit"]')
  }

  reDisableSubmit(event) {
    if (event.detail.fetchResponse.response.redirected === true)
      this.submitButton.disabled = true;
  }
}
