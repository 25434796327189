import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
    connect() {
        $("[data-toggle='tooltip']").tooltip()
    }

    async toggle(event) {
        event.currentTarget.disabled = true
        $("[data-toggle='tooltip']").tooltip('hide')

        const request = new FetchRequest(
            "POST",
            event.currentTarget.dataset.url,
            { responseKind: "turbo-stream"}
        )
        const response = await request.perform()
    }
}