import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['requestItemRow', 'hlEquivalenceIndicator', 'refresh']

  connect() {
    $(document).on('change', 'input[id^="create_from_"][type="checkbox"]', this.refreshSelectedItems)
  }

  refreshSelectedItems(_event) {
    const deleteRequestItemsButton = $('.btn-delete-items')
    const emailRequestItemsButton = $('.btn-email-items')
    const emailApprovalButton = $('.btn-email-approval')
    const requestItemsSelected = $('input:checked[id^="create_from_"][type="checkbox"]')
    const shoppingCart = $('#shopping_cart')
    const requestItemsIds = []
    let scientistPopulated = false

    // Clear the scientist info
    $('#email-selected-scientist').removeData().removeAttr('data-scientist')

    requestItemsSelected.each(function(index, checkbox) {
      requestItemsIds.push($(checkbox).val())

      if (!scientistPopulated) {
        const addToCartEl = $(checkbox).closest('.add-to-cart')
        const scientistUser = addToCartEl?.data('scientistUser') || {}

        if (Object.keys(scientistUser).length) {
          $('#email-selected-scientist').attr('data-scientist', JSON.stringify(scientistUser))
          scientistPopulated = true
        }
      }
    })

    // Show/hide delete and email items buttons
    requestItemsSelected.length > 0 ? deleteRequestItemsButton.show() : deleteRequestItemsButton.hide()
    requestItemsSelected.length > 0 ? emailRequestItemsButton.show() : emailRequestItemsButton.hide()
    requestItemsSelected.length > 0 ? emailApprovalButton.show() : emailApprovalButton.hide()

    // Adjust shopping cart
    shoppingCart.val(requestItemsIds.join(','))

    // Adjust bulk delete modal action based on selected request items
    $('#delete-request-items-form').attr('action', '/request_items/' + requestItemsIds.join(','))

    // Adjust email all form based on selected request items
    $('#email_selected_request_ids').val(requestItemsIds.join(','))

    // Adjust email approval data-url
    let button = $('#email-approval-button')
    let params = '?request_ids=' + requestItemsIds.join(',') + '&' + button.attr('data-params')
    button.attr('data-url', '/request_items/email_approval' + params )

    // Adjust selected count message
    $('#selected-count').html(`${requestItemsSelected.length} selected ${requestItemsSelected.length === 1 ? 'item' : 'items'}`)
  }

  refreshTargetConnected(_element) {
    this.refreshSelectedItems(undefined)
  }

  requestItemRowTargetConnected(element) {
    // Re-initialize tooltips
    $(element).find('[data-toggle="tooltip"]')?.tooltip()
  }

  requestItemRowTargetDisconnected(_element) {
    this.refreshSelectedItems(undefined)
  }

  disconnect() {
    $(document).off('change', 'input[id^="create_from_"][type="checkbox"]')
  }

  get editRequestItemModal() {
    return document.getElementById('edit_request_item_modal')
  }

  get deleteRequestItemModal() {
    return document.getElementById('delete_request_item_modal')
  }

  get emailApprovalModal() {
    return document.getElementById('request_item_email_approval_modal')
  }

  toggleRequestFormDisplay(event) {
    event.preventDefault()
    const formCard = $('.lab-staff-form-card')
    formCard.toggleClass('hide')
    event.target.innerHTML = formCard.hasClass('hide') ? 'Create New Request' : 'Close Form'
  }

  openEditModal(event) {
    const frame = this.editRequestItemModal
    if (!frame) return

    frame.src = event.target.dataset.url
    frame.reload()
  }

  openDeleteModal(event) {
    const frame = this.deleteRequestItemModal
    if (!frame) return

    frame.src = event.target.dataset.url
    frame.reload()
  }

  openEmailApprovalModal(event) {
    const frame = this.emailApprovalModal
    if (!frame) return

    frame.src = event.currentTarget.dataset.url
    frame.reload()
  }

  hlEquivalenceIndicatorTargetConnected(element) {
    const tooltipEl = $(element)
    tooltipEl?.tooltip({ trigger: 'hover' })
    tooltipEl?.click(function () { tooltipEl.tooltip('hide') })
  }
}
