import { Controller } from "@hotwired/stimulus"
import {isValidUrl} from "@utils/url_helpers";

export default class extends Controller {
    static targets = [
        'linkInput'
    ]

    connect() {
        this.updateLabelLink(this.linkInputTarget)
        this.refreshLabelLink(this.linkInputTarget)
    }

    updateLabelLink(target) {
        !!target.value && isValidUrl(target.value)
            ? target.dataset.labelLink = target.value
            : delete target.dataset.labelLink
    }

    refreshLabelLink(field) {
        if (!field.dataset.hasLabelLink) return

        const fieldContainer = field.closest('.form-group')
        if (!fieldContainer) return

        if (field.labels != null && field.labels[0] != null) {
            const fieldLabel = field.labels[0]

            fieldLabel.innerHTML =
                !!field.dataset.labelLink
                    ? `<a class="label-link" href="${field.dataset.labelLink}" target="_blank">${fieldLabel.innerText}</a>`
                    : fieldLabel.innerText
        }
    }
}
