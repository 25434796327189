import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  openTargetModal(event) {
    const { frameId, frameUrl } = event.target.dataset
    if (!frameId || !frameUrl) return

    const turboFrame = document.getElementById(frameId)
    if (!turboFrame) return

    turboFrame.src = frameUrl
    turboFrame.reload()
  }
}
