import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { labPublicUid: String, orderId: Number, addedToOrderAt: String }

  static targets = [
    'orderNumberSelect', 'poNumberSelect', 'filenameField', 'orderIdField', 'matchToOrderBtn',
    'imageRotationField', 'inventoryImage'
  ]

  orderNumberSelectTargetConnected(element) {
    $(element).selectize({
      valueField: 'order_number',
      labelField: 'order_number',
      searchField: 'order_number',
      create: false,
      load: (query, callback) => {
        if (!query.length) return callback()

        $.ajax({
          url: `/api/v1/labs/${this.labPublicUidValue}/orders`,
          data: {order_number: query},
          dataType: "json",
          type: 'GET',
          error: (_res) => callback(),
          success: (results) => callback(results.data.slice(0, 10))
        })
      },
      onChange: (value) => {
        const orderNumberSelectize = this.orderNumberSelectTarget.selectize
        const poNumberSelectize = this.poNumberSelectTarget.selectize

        // Clear poNumberSelectize
        if (!!poNumberSelectize) {
          poNumberSelectize.clear(true)
          poNumberSelectize.clearOptions()
        }

        this.filenameFieldTarget.innerHTML = ''
        this.orderIdFieldTarget.value = ''
        const option = orderNumberSelectize.options[value]

        if (!!option?.packingslip_filename) this.filenameFieldTarget.innerHTML = option.packingslip_filename

        if (option?.purchase_order_number?.length) {
          poNumberSelectize.addOption({ order_number: value, purchase_order_number: option.purchase_order_number })
          poNumberSelectize.setValue(option.purchase_order_number, true) // silence callbacks
        }

        if (!!option?.id) this.orderIdFieldTarget.value = option.id
      }
    })
  }

  poNumberSelectTargetConnected(element) {
    $(element).selectize({
      valueField: 'purchase_order_number',
      labelField: 'purchase_order_number',
      searchField: 'purchase_order_number',
      create: false,
      load: (query, callback) => {
        if (!query.length) return callback()

        $.ajax({
          url: `/api/v1/labs/${this.labPublicUidValue}/orders`,
          data: { po_number: query },
          dataType: "json",
          type: 'GET',
          error: (_res) => callback(),
          success: (results) => callback(results.data.slice(0, 10))
        })
      },
      onChange: (value) => {
        const poNumberSelectize = this.poNumberSelectTarget.selectize
        const orderNumberSelectize = this.orderNumberSelectTarget.selectize

        // Clear orderNumberSelectize
        if (!!orderNumberSelectize) {
          orderNumberSelectize.clear(true)
          orderNumberSelectize.clearOptions()
        }

        this.filenameFieldTarget.innerHTML = ''
        this.orderIdFieldTarget.value = ''
        const option = poNumberSelectize.options[value]

        if (!!option?.packingslip_filename) this.filenameFieldTarget.innerHTML = option.packingslip_filename

        if (option?.order_number?.length) {
          orderNumberSelectize.addOption({ order_number: option.order_number, purchase_order_number: value })
          orderNumberSelectize.setValue(option.order_number, true) // silence callbacks
        }

        if (!!option?.id) this.orderIdFieldTarget.value = option.id
      }
    })
  }

  matchToOrderBtnTargetConnected(element) {
    element.classList.toggle('d-none',!this.orderIdValue || this.addedToOrderAtValue)
  }

  rotate(event) {
    const { direction } = event.params

    const originalRotationValue = parseInt(this.imageRotationFieldTarget.value)
    let rotationDegrees

    switch (direction) {
      case 'left':
        rotationDegrees = originalRotationValue - 90
        break
      case 'right':
        rotationDegrees = originalRotationValue + 90
        break
      default:
        rotationDegrees = originalRotationValue
    }

    this.imageRotationFieldTarget.value = rotationDegrees
    this.rotateImage(rotationDegrees)
  }

  rotateImage(degrees) {
    const inventoryImageEl = $(this.inventoryImageTarget)
    const scale = (degrees % 180 === 0) ? '1' : '0.8'

    inventoryImageEl.css({
      '-webkit-transform': `rotate(${degrees}deg) scale(${scale})`,
      '-moz-transform': `rotate(${degrees}deg) scale(${scale})`,
      'transform': `rotate(${degrees}deg) scale(${scale})`
    })
  }
}
