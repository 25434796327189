import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['userIdInput', 'scientistInput']

  userIdInputTargetConnected(element) {
    $(element).selectize({
      valueField: 'id',
      labelField: 'name',
      searchField: 'name',
      create: false,
      maxItems: 1,
      onChange: value => {
        if (!!value) {
          this.userIdInputTarget.value = value
          this.scientistInputTarget.value =
            this.userIdInputTarget.closest('.form-group')?.querySelector(`div.item[data-value="${value}"]`)?.innerText
        } else {
          this.userIdInputTarget.value = ''
          this.scientistInputTarget.value = ''
        }
      }
    })

    if (element.disabled) {
      const formGroupEl = element.closest('.form-group')
      formGroupEl?.querySelector('.selectize-control')?.setAttribute('disabled', 'disabled')
      formGroupEl?.querySelector('.selectize-input input')?.setAttribute('disabled', 'disabled')
    }
  }
}
