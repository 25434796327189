import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  open() {
    $(this.element).modal('show')
  }

  close(event) {
    if (event.detail.success) {
      const keepModalOpenHeader = !!event.detail.fetchResponse.response.headers.get('X-Keep-Modal-Open')
      if (!keepModalOpenHeader) $(this.element).modal('hide')
    }
  }
}
