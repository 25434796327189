import { Controller } from '@hotwired/stimulus'
import { createRoot } from 'react-dom/client'
import { Suspense, lazy } from 'react'

export default class extends Controller {
  static values = {
    component: String,
    props: String,
    afterMountCallback: String
  }

  connect() {
    const componentSplit = this.componentValue.split('/')

    switch (componentSplit.length) {
      case 1:
        this.reactComponentPromise =
          import(`@components/${componentSplit[0]}.jsx`)
        break
      case 2:
        this.reactComponentPromise =
          import(`@components/${componentSplit[0]}/${componentSplit[1]}.jsx`)
        break
      case 3:
        this.reactComponentPromise =
          import(`@components/${componentSplit[0]}/${componentSplit[1]}/${componentSplit[2]}.jsx`)
        break
      case 4:
        this.reactComponentPromise =
          import(`@components/${componentSplit[0]}/${componentSplit[1]}/${componentSplit[2]}/${componentSplit[3]}.jsx`)
        break
    }

    this.reactComponent = lazy(() => this.reactComponentPromise)

    this._createReactRootComponent()
    this._renderReactComponent()

    // Used mainly in feature specs
    this.element.setAttribute('data-stimulus-connected', 'true')
  }

  disconnect() {
    this.reactRoot?.unmount()
  }

  _createReactRootComponent() {
    this.reactRoot = createRoot(this.element)
  }

  _renderReactComponent() {
    const $ReactComponent = this.reactComponent
    const parsedProps = JSON.parse(this.propsValue)

    const callbackProps =
      !!this.afterMountCallbackValue
        ? { callback: () => window[this.afterMountCallbackValue]?.() }
        : {}

    this.reactRoot.render(<Suspense><$ReactComponent {...parsedProps} {...callbackProps} /></Suspense>)
  }
}
