import { Controller } from '@hotwired/stimulus'
import { find } from 'lodash'

export default class extends Controller {
  static targets = ['requestItemDescription']

  connect() {
    this.scientistEmailField?.addEventListener('change', this.handleScientistEmailChange.bind(this))
    this.scientistEmailOverrideField?.addEventListener('input', this.handleScientistEmailOverrideInput.bind(this))
  }

  disconnect() {
    this.scientistEmailField?.removeEventListener('change')
    this.scientistEmailOverrideField?.removeEventListener('input')
  }

  get scientistEmailField() {
    return this.element.querySelector('#scientist_email')
  }

  get scientistEmailOverrideField() {
    return this.element.querySelector('#scientist_email_override')
  }

  get headerField() {
    return this.element.querySelector('#scientist-info')
  }

  get messageField() {
    return this.element.querySelector('#message')
  }

  get orDivider() {
    return this.element.querySelector('.or-divider')
  }

  handleBeforeFetch(event) {
    const itemDescription = $('#request_item_description')?.val()

    // Set the description param
    if (!!itemDescription && this.hasRequestItemDescriptionTarget)
      event.detail.fetchOptions.body.set('description', itemDescription)
  }

  handleScientistEmailChange(event) {
    const scientistEmail = event.target.value
    let scientistUsers

    try {
      scientistUsers = JSON.parse(event.target.dataset.scientists)
    } catch (e) {
      scientistUsers = null
    }

    const selectedScientist = find(scientistUsers || [], scientistObj => scientistObj.email === scientistEmail)

    if (selectedScientist && !!selectedScientist.first_name) {
      if (!!this.headerField) this.headerField.innerHTML = `Email to <strong>${selectedScientist.first_name}</strong>`
      if (!!this.messageField) this.messageField.value = `Hi ${selectedScientist.first_name}, `
    } else {
      if (!!this.headerField) this.headerField.innerHTML = 'Email to:'
      if (!!this.messageField) this.messageField.value = 'Hi, '
    }
  }

  handleScientistEmailOverrideInput(event) {
    if (event.target.value.length) {
      this.scientistEmailField?.classList?.toggle('d-none', true)
      this.orDivider?.classList?.toggle('d-none', true)
      if (!!this.headerField) this.headerField.innerHTML = 'Email to:'
      if (!!this.messageField) this.messageField.value = 'Hi, '
    } else {
      this.orDivider?.classList?.toggle('d-none', false)
      this.scientistEmailField?.classList?.toggle('d-none', false)
      this.scientistEmailField?.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }
}
