import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
    static targets = [
        'productProviderIdInput', 'vendorInput', 'vendorSearch'
    ]

    vendorSearchTargetConnected(element) {
        $(element).selectize({
            valueField: 'id',
            labelField: 'name',
            searchField: 'name',
            create: true,
            maxItems: 1,
            load: this.searchSuppliers,
            onChange: value => {
                if (!!value) {
                    const parsedValue = parseInt(value)

                    this.productProviderIdInputTarget.value = isNaN(parsedValue) ? '' : parsedValue
                    this.vendorInputTarget.value = this.productProviderIdInputTarget.closest('.form-group')?.querySelector(`div.item[data-value="${value}"]`)?.innerText
                } else {
                    this.productProviderIdInputTarget.value = ''
                    this.vendorInputTarget.value = ''
                }
            }
        })

        if (element.disabled) {
            const formGroupEl = element.closest('.form-group')
            formGroupEl?.querySelector('.selectize-control')?.setAttribute('disabled', 'disabled')
            formGroupEl?.querySelector('.selectize-input input')?.setAttribute('disabled', 'disabled')
        }
    }

    async searchSuppliers(term, callback) {
        const selectize = this;
        if (!term.length) return callback()

        const response = await get('/api/v1/suppliers/search', { query: { q: encodeURIComponent(term) }, responseKind: 'json' })

        if (response.ok) {
            const { data: results } = await response.json
            selectize.clearOptions()
            callback(results.slice(0, 10))
        } else {
            callback()
        }
    }
}
