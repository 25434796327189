import { Controller } from '@hotwired/stimulus'
import { find } from 'lodash'

export default class extends Controller {
    connect() {
        this.scientistEmailField?.addEventListener('change', this.handleScientistEmailChange.bind(this))
        this.scientistEmailOverrideField?.addEventListener('input', this.handleScientistEmailOverrideInput.bind(this))
        $('#emailSelectedModal').on('show.bs.modal', this.handleOpen.bind(this))
    }

    disconnect() {
        this.scientistEmailField?.removeEventListener('change')
        this.scientistEmailOverrideField?.removeEventListener('input')
    }

    get scientistEmailField() {
        return this.element.querySelector('#email_selected_scientist_email')
    }

    get scientistEmailOverrideField() {
        return this.element.querySelector('#email_selected_custom_email')
    }

    get headerField() {
        return this.element.querySelector('#email-selected-scientist')
    }

    get subjectField() {
        return this.element.querySelector('#email_selected_subject')
    }

    get messageField() {
        return this.element.querySelector('#email_selected_message')
    }

    get orDivider() {
        return this.element.querySelector('.or-divider')
    }

    get scientist() {
        if (!!this.headerField && this.headerField.dataset('scientist')) {
            return JSON.parse(this.headerField.dataset('scientist'))
        }

        return null
    }

    handleOpen() {
        const subject = 'About your purchase request'

        let header = $('#email-selected-scientist')
        let scientist = header.data('scientist')

        if (!!this.subjectField) this.subjectField.value = subject

        if (scientist) {
            if (!!this.scientistEmailField) this.scientistEmailField.value = scientist.email
        } else {
            if (!!this.scientistEmailField) this.scientistEmailField.value = ''
        }

        this.scientistEmailField?.dispatchEvent(new Event('change', { bubbles: true }))
    }

    handleScientistEmailChange(event) {
        const alert = 'Please select a scientist or enter an email in the text field below:'
        const scientistEmail = event.target.value
        const scientistUsers = JSON.parse(event.target.dataset.scientists)
        const selectedScientist = find(scientistUsers, scientistObj => scientistObj.email === scientistEmail)

        if (selectedScientist && !!selectedScientist.first_name) {
            if (!!this.headerField) this.headerField.innerHTML = `Email to <strong>${selectedScientist.first_name}</strong>`
            if (!!this.messageField) this.messageField.value = `Hi ${selectedScientist.first_name}, `
        } else {
            if (!!this.headerField) this.headerField.innerHTML = alert
            if (!!this.messageField) this.messageField.value = 'Hi, '
        }
    }

    handleScientistEmailOverrideInput(event) {
        if (event.target.value.length) {
            this.scientistEmailField?.classList?.toggle('d-none', true)
            this.orDivider?.classList?.toggle('d-none', true)
            if (!!this.headerField) this.headerField.innerHTML = 'Email to:'
            if (!!this.messageField) this.messageField.value = 'Hi, '
        } else {
            this.orDivider?.classList?.toggle('d-none', false)
            this.scientistEmailField?.classList?.toggle('d-none', false)
            this.scientistEmailField?.dispatchEvent(new Event('change', { bubbles: true }))
        }
    }
}
