import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = [
        'approvalScientistSelect'
    ]

    approvalScientistSelectTargetConnected(element) {
        $(element).selectize({
            maxItems: null,
            plugins: ['remove_button'],
            placeholder: 'Select Active Scientists',
            render: {
                item: function (item, escape) {
                    return '<span class="badge badge-pill badge-status badge-email">' +
                        '<i class="fas fa-envelope status-icon"/>' +
                        item.text +
                        '</span>';
                }
            }
        })

        if (element.disabled) {
            const formGroupEl = element.closest('.form-group')
            formGroupEl?.querySelector('.selectize-control')?.setAttribute('disabled', 'disabled')
            formGroupEl?.querySelector('.selectize-input input')?.setAttribute('disabled', 'disabled')
        }
    }
}
