import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['quantityInput']

  quantityInputTargetConnected(element) {
    setTimeout(() => {
      element.focus()
    }, 500)
  }
}
