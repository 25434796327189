import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  get configurationModal() {
    return document.getElementById('invoice_configuration_modal')
  }

  openConfigurationModal(event) {
    const frame = this.configurationModal
    if (!frame) return

    frame.src = event.currentTarget.dataset.url
    frame.reload()
  }
}
