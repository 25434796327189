import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import { sumReducer } from '@utils/calculations'

export default class extends Controller {
  static values = {
    showOrderDetails: { type: Boolean, default: false },
    returnTo: { type: String, default: '' },
    labPublicUid: String
  }

  static targets = [
    'requiredField', 'supplierSearch', 'productProviderIdInput', 'orderDetailsHeading', 'orderDetailsContent',
    'shipment', 'lineItemShipmentIndexSelect', 'totalCostInput', 'documentsList', 'productProviderResults',
    'discountAmountInput', 'discountAmountIcon'
  ]

  connect() {
    this.newOrderPageUnsavedChangesModal()
    this.updateDiscountAmountLink()
    this.initializeTooltips()
  }

  newOrderPageUnsavedChangesModal() {
    if (!window.location.pathname.match(/^\/labs\/\d+\/orders\/new$/)) return

    const defaultFields = ['order[ordered_at]']

    $("a:not('.document-link'):not('.label-link'):not('.btn-add-shipment'):not('.btn-add-line-item'):not('.btn-add-document')").click(event => {
      const answeredFields = $('.form-control').filter(function() {
        return defaultFields.indexOf(this.name) === -1 && this.value?.length > 0
      })

      if (answeredFields.length > 0) {
        event.preventDefault()
        $('#unsavedChangesModal').modal()
        $('.btn-confirm-leaving').click(function() {
          window.location.href = event.target.href || $(event.target).closest('a').attr('href')
        })
      }
    })
  }

  supplierSearchTargetConnected() {
    $(this.supplierSearchTarget).selectize({
      valueField: 'id',
      labelField: 'name',
      searchField: 'name',
      create: false,
      maxItems: 1,
      persist: false,
      selectOnTab: true,
      preload: 'focus',
      options: this.productProviderResults,
      load: (value, callback) => this.searchSuppliers(value, callback),
      plugins: ['restore_on_backspace'],
      onItemRemove: function (searchTerm, _obj) {
        // Trigger a search when an item is removed (backspace)
        if (!!searchTerm) this.onSearchChange(searchTerm)
      },
      onChange: value => {
        this.productProviderIdInputTarget.value = value

        !!value
          ? this.productProviderIdInputTarget.dataset.labelLink = `/suppliers/${value}/edit`
          : delete this.productProviderIdInputTarget.dataset.labelLink

        !!value
          ? this.getActivePromos(value)
          : this.hideDiscountAmountIcon()

        // Dispatch events in order to trigger form controller validations and enable/disable submit button
        this.productProviderIdInputTarget.dispatchEvent(new Event('input', { bubbles: true }))
        this.productProviderIdInputTarget.dispatchEvent(new Event('blur', { bubbles: true }))
      },
      // We use the onFocus in conjunction with the restore_on_backspace plugin to allow the user to more
      // easily select the search contents
      onFocus: () => {
        const selectize = this.supplierSearchTarget.selectize
        const currentVal = selectize?.getValue()

        if (currentVal?.length) {
          const currentSearchText = selectize.getItem(currentVal).text()

          selectize.clear(false)
          selectize.setTextboxValue(currentSearchText)
        }
      },
      // Whenever there's a blur, if the search text matches one of the loaded search options by label,
      // we manually set the selectize value, otherwise the field would be cleared
      onBlur: () => {
        const selectize = this.supplierSearchTarget.selectize
        const lastQuery = selectize?.lastQuery

        if (!!lastQuery && !!this.productProviderResults) {
          const matchSearchItem =
            this.productProviderResults.find(item => item.name?.toLowerCase() === lastQuery.toLowerCase())

          if (!!matchSearchItem && matchSearchItem?.id) {
            selectize.setTextboxValue(lastQuery)
            selectize.setValue(matchSearchItem.id, false)
            this.productProviderIdInputTarget.value = matchSearchItem.id

            // Dispatch events in order to trigger form controller validations and enable/disable submit button
            this.productProviderIdInputTarget.dispatchEvent(new Event('input', { bubbles: true }))
            this.productProviderIdInputTarget.dispatchEvent(new Event('blur', { bubbles: true }))
          }
        }
      }
    })

    $(this.supplierSearchTarget)[0].selectize.focus()
  }

  async searchSuppliers(term, callback) {
    const selectize = this.supplierSearchTarget.selectize
    if (!term.length) return callback()

    const response = await get('/api/v1/suppliers/search', { query: { q: term }, responseKind: 'json' })

    if (response.ok) {
      const { data: results } = await response.json
      selectize.clearOptions()
      callback(results.slice(0, 10))
      this.productProviderResultsTarget.value = JSON.stringify(results)
    } else {
      callback()
    }
  }

  async getActivePromos(supplierId) {
    const response = await get(`/api/v1/suppliers/${supplierId}/promos`, { responseKind: 'json' })

    if (response.ok) {
      const { data: json } = await response.json

      if (!!json.has_promos) {
        this.showDiscountAmountIcon()
      } else {
        this.hideDiscountAmountIcon()
      }
    } else {
      this.hideDiscountAmountIcon()
    }
  }

  hideDiscountAmountIcon() {
    this.discountAmountIconTarget.classList.add('invisible')
  }

  showDiscountAmountIcon() {
    this.discountAmountIconTarget.classList.remove('invisible')
  }

  initializeTooltips() {
    $("[data-toggle='tooltip']").tooltip()
  }

  get productProviderResults() {
    let jsonParsedResults = null

    try {
      // Do not try to parse an empty string
      if (!!this.productProviderResultsTarget.value)
        jsonParsedResults = JSON.parse(this.productProviderResultsTarget.value)
    } catch (e) {
      // Catch any parsing error - sets the parsed results to null
      jsonParsedResults = null
    }

    return jsonParsedResults || []
  }

  updateDiscountAmountLink() {
    const discountAmountInput = this.discountAmountInputTarget
    if (!discountAmountInput) return

    discountAmountInput.dataset.labelLink = `/dashboard/promosforscientistssavingmoney`
  }

  toggleOrderDetails(event) {
    event.preventDefault()
    this.showOrderDetailsValue = !this.showOrderDetailsValue
  }

  showOrderDetailsValueChanged() {
    const headingEl = this.orderDetailsHeadingTarget
    const contentEl = this.orderDetailsContentTarget
    if (!headingEl || !contentEl) return

    headingEl.classList.toggle('active', this.showOrderDetailsValue)
    headingEl.querySelector('div#order-details--heading-content').classList.toggle('active', this.showOrderDetailsValue)
    headingEl.querySelector('span#order-details--heading-title').classList.toggle('d-none', !this.showOrderDetailsValue)
    headingEl.querySelector('button#order-details--toggle-btn').innerHTML = this.showOrderDetailsValue ? 'Hide' : 'Show Details'
    contentEl.classList.toggle('d-none', !this.showOrderDetailsValue)
  }

  shipmentTargetConnected(_element) {
    this.adjustShipmentIndexes()
    this.setShipmentsCount()
    this.handleLineItemsShipmentIndexSelect()
    this.calculateTotal()
  }

  shipmentTargetDisconnected(_element) {
    this.adjustShipmentIndexes()
    this.setShipmentsCount()
    this.handleLineItemsShipmentIndexSelect()
    this.calculateTotal()
  }

  adjustShipmentIndexes() {
    this.shipmentTargets.forEach((item, index) => {
      // Sets the shipmentIndexValue - which in turn triggers the shipmentIndexValueChanged callback in the Shipment controller
      item.dataset.shipmentShipmentIndexValue = index
    })
  }

  setShipmentsCount() {
    // Set shipments count
    const shipmentsCountEl = this.element.querySelector('span.shipments-count')

    if (shipmentsCountEl) {
      const shipmentsCount = this.shipmentTargets.length
      const countableShipment = shipmentsCount === 1 ? 'Shipment' : 'Shipments'

      shipmentsCountEl.innerHTML = `${shipmentsCount} ${countableShipment}`
    }
  }

  lineItemShipmentIndexSelectTargetConnected(element) {
    this.handleLineItemsShipmentIndexSelect()
    element.addEventListener('change', this.boundMoveLineItemHandler)
  }

  lineItemShipmentIndexSelectTargetDisconnected(element) {
    element.removeEventListener('change', this.boundMoveLineItemHandler)
  }

  handleLineItemsShipmentIndexSelect() {
    this.lineItemShipmentIndexSelectTargets.forEach(item => {
      const shipmentsCount = this.shipmentTargets.length
      item.disabled = shipmentsCount === 1

      for (let i = 0; i < shipmentsCount; i++) {
        item.options[i] = new Option((i + 1).toString(), i.toString())
      }

      const shipmentEl = item.closest('div.row.shipment')
      if (shipmentEl) item.value = shipmentEl.dataset.shipmentShipmentIndexValue
    });
  }

  get boundMoveLineItemHandler() {
    return this.handleMoveLineItem.bind(this)
  }

  handleMoveLineItem(event) {
    const sourceLineItemController = this.application.getControllerForElementAndIdentifier(event.target.closest('div[data-controller="line-item"]'), 'line-item')
    const sourceLineItemObj = sourceLineItemController.lineItemObj

    const targetShipment = this.shipmentTargets.find(shipment => shipment.dataset.shipmentShipmentIndexValue === event.target.value)
    const targetShipmentController = this.application.getControllerForElementAndIdentifier(targetShipment, 'shipment')

    targetShipmentController.addNewLineItem(sourceLineItemObj) // Add new lineItem on target shipment
    sourceLineItemController.deleteLineItem() // Remove the source lineItem
  }

  calculateTotal() {
    const shipmentTotals = this.shipmentTargets.map(item => Number(item.querySelector('input[name$="[total_cost]"]').value))
    const totalCost = shipmentTotals.reduce(sumReducer, 0)

    this.totalCostInputTarget.value = totalCost
    this.element.querySelector('span.order-total-value').innerHTML = `$${(totalCost || 0).toFixed(2)}`
  }

  cancel(event) {
    event.preventDefault()
    this.goTo(this.returnToValue || 'bottom_line')
  }

  goTo(next = 'bottom_line') {
    let queryParams = ''
    if (next === 'bottom_line') queryParams = `?f=${docCookies.getItem('f') || ''}&q=${encodeURIComponent(docCookies.getItem('q') || '')}&start_date=${docCookies.getItem('start_date') || ''}&end_date=${docCookies.getItem('end_date') || ''}`
    if (!!this.labPublicUidValue) window.location = `/labs/${this.labPublicUidValue}/${next}${queryParams}`
  }

  handleAddDocument(_event) {
    this.documentsListTarget.classList.remove('d-none')
  }
}
