import { Controller } from '@hotwired/stimulus'
import { gtag } from "@utils/url_helpers";

export default class extends Controller {
    static values = {
        labPublicUid: String,
        itemType: String,
        itemId: String
    }
    connect() {
      setTimeout(function() {
          document.getElementById('vlm_message').focus()
      }, 500)
    }

    emailSent(event) {
        gtag('event', 'scientist_email_sent', {
            'lab_public_uid': this.labPublicUidValue,
            'item_type': this.itemTypeValue,
            'item_id': this.itemIdValue
        })
    }
}
