import FormController from '@controllers/form_controller'
import { includes } from 'lodash'

/**
 * Usage: <%= form_with(url:, model:, method: :post, html: { data: { controller: 'order_form' } }) do |f| %>
 * */

export default class extends FormController {
  // Overwritten method
  connect() {
    super.connect()
    super.validateForm()
  }

  // Overwritten method
  validateField (field) {
    if (!this.shouldValidateField(field)) return true
    if (includes(field.classList, 'document-link')) return true
    if (includes(field.classList, 'label-link')) return true

    const isValid = field.checkValidity()
    if (!isValid) this.submitButton.disabled = true

    this.refreshErrorForInvalidField(field, isValid)
    this.refreshLabelLink(field)
    return isValid
  }

  // Overwritten method
  removeExistingErrorMessage (field) {
    const fieldContainer = field.closest('.form-group')
    if (!fieldContainer) return

    if (field.labels != null && field.labels[0] != null) {
      const fieldLabel = field.labels[0]

      fieldLabel.classList.remove('required', 'invalid')
      delete fieldLabel.dataset.toggle
      delete fieldLabel.dataset.originalTitle
    }
  }

  // Overwritten method
  showErrorForInvalidField (field) {
    const fieldContainer = field.closest('.form-group')
    if (!fieldContainer) return

    if (field.labels != null && field.labels[0] != null) {
      const fieldLabel = field.labels[0]

      const fieldValidity = field.validity
      let errorClassName = 'invalid'
      let errorMessage = field.validationMessage

      // Custom error messages
      if (fieldValidity?.valueMissing) {
        errorClassName = 'required'
        errorMessage = `The ${fieldLabel.innerText} field is required.`
      } else if (fieldValidity?.tooShort || fieldValidity.tooLong) {
        errorClassName = 'invalid'

        if (field.minLength !== -1)
          errorMessage = `The ${fieldLabel.innerText} must have at least ${field.minLength} characters.`

        if (field.maxLength !== -1)
          errorMessage = `The ${fieldLabel.innerText} must have less than ${field.maxLength} characters.`

        if (field.minLength !== -1 && field.maxLength !== -1)
          errorMessage = `The ${fieldLabel.innerText} must be between ${field.minLength} and ${field.maxLength} characters.`
      }

      fieldLabel.classList.add(errorClassName)
      fieldLabel.dataset.toggle = 'tooltip'
      fieldLabel.dataset.originalTitle = errorMessage
      $(fieldLabel).tooltip()
    }
  }

  refreshLabelLink(field) {
    if (!field.dataset.hasLabelLink) return

    const fieldContainer = field.closest('.form-group')
    if (!fieldContainer) return

    if (field.labels != null && field.labels[0] != null) {
      const fieldLabel = field.labels[0]

      fieldLabel.innerHTML =
        !!field.dataset.labelLink
          ? `<a class="label-link" href="${field.dataset.labelLink}" target="_blank">${fieldLabel.innerText}</a>`
          : fieldLabel.innerText
    }
  }
}
