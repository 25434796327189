import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    openModal(event) {
        const frame = document.getElementById(event.currentTarget.dataset.target)
        if (!frame) return

        frame.src = event.currentTarget.dataset.url
        frame.reload()
    }
}
