import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import { find } from 'lodash'
import { objectToQueryString, isValidUrl } from '@utils/url_helpers'

export default class extends Controller {
  static values = {
    labPublicUid: String,
    shipmentId: String,
    canMarkChemicalOrCold: Boolean,
    lineItemIndex: { type: Number, default: -1 },
    lineItemCost: { type: Number, default: 0 }
  }

  static targets = [
    'scientistInput', 'userIdSelect', 'catalogNumberSearch', 'catalogNumberInput', 'productIdInput', 'catalogNumberResults',
    'descriptionInput', 'quantityInput', 'unitPriceInput', 'productLinkInput', 'indicatorCheckbox', 'copyCatalogNumberIcon'
  ]

  lineItemIndexValueChanged(currentIndex, _oldIndex) {
    if (currentIndex === -1) return

    const lineItemIndexEl = this.element.querySelector('span.line-item-index')
    const lineItemRowEl = this.element.querySelector('div.row.line-item')

    if (lineItemIndexEl) lineItemIndexEl.innerHTML = currentIndex + 1
    if (lineItemRowEl) lineItemRowEl.classList.toggle('darker-background', (currentIndex) % 2 !== 0)
  }

  userIdSelectTargetConnected(element) {
    element.addEventListener('change', event => {
      const selectedOption = element.options[event.target.selectedIndex]

      this.scientistInputTarget.value = !!selectedOption.value ? selectedOption.text : ''
      this.scientistInputTarget.dispatchEvent(new Event('input', { bubbles: true }))
      this.scientistInputTarget.dispatchEvent(new Event('blur', { bubbles: true }))
    })
  }

  userIdSelectTargetDisconnected(element) {
    element.removeEventListener('change')
  }

  productLinkInputTargetConnected(element) {
    element.addEventListener('change', event => {
      !!event.target.value && isValidUrl(event.target.value)
        ? element.dataset.labelLink = event.target.value
        : delete element.dataset.labelLink
    })

    element.addEventListener('paste', event => {
      const pasteData = (event.clipboardData || window.clipboardData)?.getData('text')

      !!pasteData && isValidUrl(pasteData)
        ? element.dataset.labelLink = pasteData
        : delete element.dataset.labelLink

      // Manually trigger the blur event (force update on label link)
      event.target.dispatchEvent(new Event('blur', { bubbles: true }))
    })

    element.addEventListener('keyup', event => {
      !!event.target.value && isValidUrl(event.target.value)
        ? element.dataset.labelLink = event.target.value
        : delete element.dataset.labelLink

      // Manually trigger the blur event (force update on label link)
      event.target.dispatchEvent(new Event('blur', { bubbles: true }))
    })
  }

  productLinkInputTargetDisconnected(element) {
    element.removeEventListener('change')
    element.removeEventListener('paste')
    element.removeEventListener('keyup')
  }

  catalogNumberSearchTargetConnected() {
    $(this.catalogNumberSearchTarget).selectize({
      valueField: 'id',
      labelField: 'name',
      searchField: 'name',
      create: true,
      maxItems: 1,
      persist: false,
      selectOnTab: false,
      createOnBlur: true,
      plugins: ['restore_on_backspace'],
      delimiter: '==|==', // Ensure comma-separated values are accepted on copy/paste
      load: (value, callback) => this.searchProducts(value, callback),
      onItemRemove: function (catalogNumber, _obj) {
        // Trigger a search when an item is removed (backspace)
        if (!!catalogNumber) this.onSearchChange(catalogNumber)
      },
      onFocus: () => {
        const selectize = this.catalogNumberSearchTarget.selectize
        const currentVal = selectize?.getValue()

        if (currentVal?.length) {
          const currentSearchText = selectize.getItem(currentVal).text()

          selectize.clear(false)
          selectize.setTextboxValue(currentSearchText)
        }
      },
      onChange: value => {
        if (!!value) {
          const parsedValue = Number(value)

          // If it's a valid number, it means a valid product was selected from the list
          if (!isNaN(parsedValue)) {
            const selectedProduct = find(this.catalogNumberResults, { id: parsedValue })

            if (!!selectedProduct){
              this.productIdInputTarget.value = parsedValue
              this.descriptionInputTarget.value = selectedProduct.description
              this.descriptionInputTarget.dispatchEvent(new Event('input', { bubbles: true }))
              this.descriptionInputTarget.dispatchEvent(new Event('blur', { bubbles: true }))
            }
          }

          this.catalogNumberInputTarget.value =
            this.catalogNumberSearchTarget.closest('.form-group')?.querySelector(`div.item[data-value="${value}"]`)?.innerText
        } else {
          this.productIdInputTarget.value = ''
          this.catalogNumberInputTarget.value = ''
        }
      }
    })
  }

  async searchProducts(catalogNumber, callback) {
    const selectize = this.catalogNumberSearchTarget.selectize
    if (!catalogNumber.length) return callback()

    const response =
      await get('/api/v1/products/search', { query: { q: encodeURIComponent(catalogNumber) }, responseKind: 'json' })

    if (response.ok) {
      const { data: results } = await response.json
      selectize.clearOptions()
      callback(results.slice(0, 10))
      this.catalogNumberResultsTarget.value = JSON.stringify(results)
    } else {
      callback()
    }
  }

  get catalogNumberResults() {
    let jsonParsedResults = null

    try {
      // Do not try to parse an empty string
      if (!!this.catalogNumberResultsTarget.value)
        jsonParsedResults = JSON.parse(this.catalogNumberResultsTarget.value)
    } catch (e) {
      // Catch any parsing error - sets the parsed results to null
      jsonParsedResults = null
    }

    return jsonParsedResults || []
  }

  quantityInputTargetConnected(element) {
    element.addEventListener('change', this.boundCalculateLineItemCost)
  }

  quantityInputTargetDisconnected(element) {
    element.removeEventListener('change', this.boundCalculateLineItemCost)
  }

  unitPriceInputTargetConnected(element) {
    element.addEventListener('change', this.boundCalculateLineItemCost)
  }

  unitPriceInputTargetDisconnected(element) {
    element.removeEventListener('change', this.boundCalculateLineItemCost)
  }

  get boundCalculateLineItemCost() {
    return this.calculateLineItemCost.bind(this)
  }

  calculateLineItemCost() {
    this.lineItemCostValue =
      (this.unitPriceInputTarget.checkValidity() && this.quantityInputTarget.checkValidity())
        ? Number(this.unitPriceInputTarget.value || 0) * Number(this.quantityInputTarget.value)
        : 0

    this.dispatch('costChanged', {})
  }

  deleteLineItem(event) {
    event?.preventDefault()
    this.element.remove()
  }

  get lineItemObj() {
    const lineItem = {}
    const copyInputFieldNames = ['lab_id', 'request_item_id', 'description', 'scientist', 'catalog_number', 'product_id', 'quantity', 'unit_price', 'product_link']
    const copySelectFieldNames = ['user_id']

    if (this.canMarkChemicalOrColdValue) {
      // If the user has edit access to chemical/cold checkboxes, we need to copy them using the inputEl.checked option
      const copyCheckboxFieldNames = ['chemical', 'cold']

      copyCheckboxFieldNames.forEach(fieldName => {
        const checkboxEl = this.element.querySelector(`input.styled-checkbox[name$="[${fieldName}]"]`)
        if (checkboxEl) lineItem[fieldName] = checkboxEl.checked
      })
    } else {
      // We also want to copy the hidden chemical/cold inputs
      copyInputFieldNames.push('chemical', 'cold')
    }

    copyInputFieldNames.forEach(fieldName => {
      const inputEl = this.element.querySelector(`input[name$="[${fieldName}]"]`)
      if (inputEl) lineItem[fieldName] = inputEl.value
    });

    copySelectFieldNames.forEach(fieldName => {
      const selectEl = this.element.querySelector(`select[name$="[${fieldName}]"]`)
      if (selectEl) lineItem[fieldName] = selectEl.value
    })

    const notesEl = this.element.querySelector('div[name="notes"]')
    if (notesEl) lineItem['notes'] = notesEl.innerText || ''

    return lineItem
  }

  async copyLineItem(event) {
    event.preventDefault()
    const sourceWrapperEl = event.target.closest('.item-container')

    const params = { shipment_id: this.shipmentIdValue, line_item: this.lineItemObj }
    if (!!sourceWrapperEl) params['source_element_id'] = sourceWrapperEl.id

    const response =
      await get(`/labs/${this.labPublicUidValue}/shipment/shipment_line_item?${objectToQueryString(params)}`, {
        credentials: 'same-origin',
        responseKind: 'turbo-stream'
      })

    if (!response.ok) console.error('Failed to copy line item')
  }

  get descriptionIconMapping() {
    return { 'chemical': '⚠️ ', 'cold': '❄️ ' }
  }

  indicatorCheckboxTargetConnected(element) {
    element.addEventListener('change', event => {
      const { checked, id } = event.target;
      const fieldType = id.split('-')[0]

      this.toggleDescriptionIcon(fieldType, checked)
    })
  }

  indicatorCheckboxTargetDisconnected(element) {
    element.removeEventListener('change')
  }

  toggleDescriptionIcon(fieldType, checked) {
    const icon = this.descriptionIconMapping[fieldType]
    const currentDescription = this.descriptionInputTarget.value

    if (checked) {
      if (!currentDescription.includes(icon))
        this.descriptionInputTarget.value = `${icon}${currentDescription}`
    } else {
      this.descriptionInputTarget.value = currentDescription.replace(icon, '')
    }

    this.descriptionInputTarget.dispatchEvent(new Event('input', { bubbles: true }))
    this.descriptionInputTarget.dispatchEvent(new Event('blur', { bubbles: true }))
  }

  async copyCatalogNumber(event) {
    try {
      event.stopPropagation()
      event.preventDefault()

      const contentToCopy = this.catalogNumberInputTarget.value

      if (!!contentToCopy && contentToCopy.length) {
        await navigator.clipboard.writeText(contentToCopy)
        this.copyCatalogNumberIconTarget.className = 'fas fa-check copy-icon text'
        setTimeout(() => { this.copyCatalogNumberIconTarget.className = 'far fa-copy copy-icon' }, 2000)
      }
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  }
}
