import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    if (!!this.scientistsSelect) {
      $(this.scientistsSelect).selectize({
        maxItems: null,
        plugins: ['remove_button'],
        placeholder: 'Select Active Scientists',
        render: {
          item: function (item, escape) {
            return '<span class="badge badge-pill badge-status badge-email">' +
              '<i class="fas fa-envelope status-icon"/>' +
              item.text +
              '</span>';
          }
        }
      })
    }
  }

  get scientistsSelect() {
    return document.getElementById('arriving-today-scientists')
  }
}
